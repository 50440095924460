import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { ReactElementLike } from 'prop-types';
import { isArray } from 'lodash';
import { Navigate, Outlet } from 'react-router-dom';
import {
  getStaffById,
  authentication,
  getStaffActions,
  StaffRelatedWarehouses,
  getStaffRelatedWarehouses,
} from '@/services/Staff';
import { Authentication, StaffWithToken } from '@/interfaces/Staff';
import { getPersonalRackByUser } from '@/services/Racks';
import Rack from '@/interfaces/Rack';
import Optional from '@/types/Optional';
import Warehouse from '@/interfaces/Warehouse';
import usePermissions from '@/hooks/usePermissions';
import PageActions from '@/interfaces/PageActions';
import EBasicActions from '@/enums/EBasicActions';
import EPages from '@/enums/EPages';

type RequiredActions = { code: string, required: boolean };
export interface IAuth {
  staff: StaffWithToken | null;
  warehouse: Optional<Warehouse['id']>;
  relatedWarehouses: StaffRelatedWarehouses[];
  selectedWarehouses: Warehouse['id'][];
  rack: Rack | null;
  actions: PageActions | null;
  loading: boolean;
  login: (values: Authentication) => Promise<boolean>;
  logout: () => void;
  setWarehouseContext: (newWarehouse: string) => void;
  setSelectedWarehouses: (value: Warehouse['id'] | Warehouse['id'][]) => void;
}

interface IChildren {
  children: ReactElementLike;
}

interface IRequireAuth extends IChildren {
  pageName?: EPages;
  permissions?: EBasicActions | EBasicActions[];
}

const AuthContext = createContext<IAuth | null>(null);

function useAuth(): IAuth {
  let initUser = null;
  let initWh = null;
  let initRk = null;
  let initActions = null;

  if ('staff' in localStorage) {
    initUser = localStorage.getItem('staff');
    initUser = JSON.parse(initUser === null ? '' : initUser);
  }

  if ('warehouse' in localStorage) {
    initWh = localStorage.getItem('warehouse');
    initWh = JSON.parse(initWh === null ? '' : initWh);
  }

  if ('rack' in localStorage) {
    initRk = localStorage.getItem('rack');
    initRk = JSON.parse(initRk === null ? '' : initRk);
  }

  if ('actions' in localStorage) {
    initActions = localStorage.getItem('actions');
    initActions = JSON.parse(initActions === null ? '' : initActions);
  }

  const [staff, setStaff] = useState<StaffWithToken | null>(initUser);
  const [warehouse, setWarehouse] = useState<Optional<Warehouse['id']>>(initWh);
  const [relatedWarehouses, setRelatedWarehouses] = useState<StaffRelatedWarehouses[]>([]);
  const [selectedWarehouses, setSelectedWarehousesState] = useState<Warehouse['id'][]>(() => {
    if (!('warehouses' in localStorage)) {
      return [];
    }
    const value = localStorage.getItem('warehouses');
    // warehouses: is null or empty
    if (value === null || value === '') {
      return [];
    }
    return JSON.parse(value);
  });
  const [rack, setRack] = useState<Rack | null>(initRk);
  const [actions, setActions] = useState<PageActions | null>(initActions);
  const [loading, setLoading] = useState<boolean>(false);

  const setWarehouseContext = (newWarehouse: Optional<Warehouse['id']>) => {
    if (!newWarehouse) {
      setWarehouse(newWarehouse);
      setPersonalRack();
      return;
    }
    localStorage.setItem('warehouse', JSON.parse(newWarehouse));
    setWarehouse(newWarehouse);
    setPersonalRack();
  };

  const setSelectedWarehouses = (value: Warehouse['id'] | Warehouse['id'][]) => {
    const warehousesArr = Array.isArray(value) ? value : [value];
    localStorage.setItem('warehouses', JSON.stringify(warehousesArr));
    setSelectedWarehousesState(warehousesArr);
  };

  const setPersonalRack = async () => {
    const rackData: Rack = await getPersonalRackByUser((staff ? staff.id : 0));

    if (rackData !== undefined) {
      localStorage.setItem('rack', JSON.stringify(rackData.id));
      setRack(rackData);
    } else {
      removeRackContext();
    }
  };

  const setStaffContext = (newStaff: StaffWithToken) => {
    localStorage.setItem('staff', JSON.stringify(newStaff));
    setStaff(newStaff);
  };

  const setActionContext = (newActions: PageActions) => {
    localStorage.setItem('actions', JSON.stringify(newActions));
    setActions(newActions);
  };

  const removeWarehouseContext = () => {
    localStorage.removeItem('warehouse');
    setWarehouse(null);
  };

  const removeRackContext = () => {
    localStorage.removeItem('rack');
    setRack(null);
  };

  const removeStaffContext = () => {
    localStorage.removeItem('staff');
    setStaff(null);
  };

  const removeActionsContext = () => {
    localStorage.removeItem('actions');
    setActions(null);
  };

  const login = async (values: Authentication): Promise<boolean> => {
    setLoading(true);

    try {
      const resStaff = await authentication({ ...values });
      const resActions = await getStaffActions(resStaff.id);
      setStaffContext(resStaff);
      setActionContext(resActions);
      return true;
    } catch (error) {
      console.error(error);
      removeStaffContext();
      removeWarehouseContext();
      removeRackContext();
      removeActionsContext();
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateStaff = (staffId: number) => {
    getStaffById(staffId)
      .then((resStaff) => {
        const newStaff: StaffWithToken = {
          ...resStaff,
          accessToken: staff?.accessToken ?? '',
        };
        setStaffContext(newStaff);
      })
      .catch((err) => {
        console.error(err);
        setStaff(null);
      });

    getStaffRelatedWarehouses({ staffId })
      .then((warehouses) => {
        setRelatedWarehouses(warehouses);

        // remove possible selected warehouses context if are not related to user
        const relatedWarehousesIds = warehouses.map(relatedWh => relatedWh.id.toString());
        const filteredWhs = selectedWarehouses
          .filter(selectedWhId => relatedWarehousesIds.includes(selectedWhId.toString()));
        setSelectedWarehouses(filteredWhs);
      })
      .catch((err) => {
        console.error(err);
        setRelatedWarehouses([]);
      });
  };

  const logout = () => {
    removeStaffContext();
    removeWarehouseContext();
    removeRackContext();
    removeActionsContext();
  };

  useEffect(() => {
    if (staff?.id) {
      updateStaff(staff?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    staff,
    warehouse,
    selectedWarehouses,
    relatedWarehouses,
    rack,
    actions,
    loading,
    login,
    logout,
    setWarehouseContext,
    setSelectedWarehouses,
  };
}

export function AuthProvider({ children }: IChildren) {
  const auth = useAuth();

  return (

    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

export function PrivateRoute({
  pageName, permissions, children,
}: IRequireAuth) {
  const staffContext = useContext(AuthContext);
  const { isValidAction } = usePermissions();

  const permissionsToValidate = permissions;

  if (!staffContext?.staff) return <Navigate to="/login" replace />;
  if (!permissionsToValidate || !pageName) return children ?? <Outlet />;

  let isAuthorized: boolean = false;

  if (isArray(permissionsToValidate) && permissionsToValidate.length > 0) {
    isAuthorized = evaluatePermissions(permissionsToValidate, pageName, isValidAction);
  }

  if (!isArray(permissionsToValidate)) {
    isAuthorized = evaluatePermissions([permissionsToValidate], pageName, isValidAction);
  }

  if (!isAuthorized) return <Navigate to="/403" replace />;

  return children ?? <Outlet />;
}

const evaluatePermissions = (
  permissions: EBasicActions[],
  pageName: EPages,
  isValidAction: (action: EBasicActions, page: EPages) => boolean,
) => {
  const isValidPermissions: boolean[] = [];

  permissions.forEach((permission) => {
    const isValid = isValidAction(permission, pageName);
    isValidPermissions.push(isValid);
  });

  return isValidPermissions.every(isValidPermission => isValidPermission);
};

export default function AuthConsumer() {
  return useContext(AuthContext);
}


export type OperativeGroup = {
  id: string;
  alias: string;
  warehouses: {
    id: string,
    alias: string,
  }[];
};
export const groupWarehouses = (relatedWarehouses: StaffRelatedWarehouses[]): Record<string, OperativeGroup> => {
  const reducedList = relatedWarehouses.reduce((acc, warehouse) => {
    const { operativeAlias } = warehouse;
    const operativeId = warehouse.operativeId.toString();
    if (!(operativeId in acc)) {
      acc[operativeId] = {
        id: operativeId,
        alias: operativeAlias,
        warehouses: [],
      };
    }

    acc[operativeId].warehouses.push({
      id: warehouse.id,
      alias: warehouse.alias,
    });
    return acc;
  }, {} as Record<string, OperativeGroup>);

  return reducedList;
};
