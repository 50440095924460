enum EPage {
  Discounts = 'discounts',
  Coupons = 'coupons',
  Categories = 'categories',
  Pages = 'pages',
  Products = 'products',
  Variants = 'variants',
  Mixes = 'mixes',
  Banners = 'banners',
  Badges = 'badges',
  Roles = 'roles',
  Actions = 'actions',
  Franchises = 'franchises',
  Operatives = 'operatives',
  MasterFranchises = 'master_franchises',
  Coverages = 'coverages',
  CoveragesPostalCodes = 'coverages_postal_codes',
  DeliveryPoints = 'delivery_points',
  Providers = 'providers',
  ProviderProducts = 'provider_products',
  ProviderProductFormats = 'provider_product_formats',
  ProviderAgreements = 'provider_agreements',
  Staff = 'staff',
  StaffPwd = 'staff_pwd',
  StaffWarehouses = 'staff_warehouses',
  StaffRoles = 'staff_roles',
  DeliveryMethods = 'delivery_methods',
  Zones = 'zones',
  Warehouses = 'warehouses',
  ShippingTypes = 'shipping_types',
  WarehouseStaff = 'warehouse_staff',
  WarehouseDeliveryPoints = 'warehouse_delivery_points',
  ProductShippingTypes = 'product_shipping_types',
  PostalCodes = 'postal_codes',
  WarehouseProducts = 'warehouse_products',
  WarehouseVariants = 'warehouse_variants',
  WarehouseMixes = 'warehouse_mixes',
  OperativePaymentServices = 'operative_payments_services',
  OperativeProducts = 'operative_products',
  OperativeVariants = 'operative_variants',
  OperativeMixes = 'operative_mixes',
  Solpeds = 'solpeds',
  SupplyOrders = 'supply_orders',
  Issues = 'issues',
  EntryPlans = 'entry_plans',
  Racks = 'racks',
  ProductInventories = 'product_inventories',
  InventoryMovements= 'inventory_movements',
  Orders = 'orders',
  OrderProducts = 'order_products',
  OrderCustomer = 'order_customer',
  OrderPayment = 'order_payment',
  OrderDeliveries = 'order_deliveries',
  Lpns = 'lpns',
  Deliveries = 'deliveries',
  DeliveriesPacking = 'deliveries_packing',
  MembershipControl = 'membership_control',
  Quantifiers = 'quantifiers',
  Establishments = 'establishments',
  EstablishmentProducts = 'establishment_products',
  EstablishmentMixes = 'establishment_mixes',
  EstablishmentSaleOrigins = 'establishment_sale_origins',
  EstablishmentStaff = 'establishment_staff',
  EstablishmentTerminals = 'establishment_terminals',
  EstablishmentServiceLocations = 'establishment_service_locations',
  EstablishmentOperativeLocations = 'establishment_operative_locations',
  EstablishmentOperativeLocationProducts = 'establishment_operative_location_products',
  EstablishmentOperativeLocationMixes = 'establishment_operative_location_mixes',
  AccountIssueCategoriesCatalog = 'account_issue_categories_catalog',
  AccountIssueTypesCatalog = 'account_issue_types_catalog',
  EstablishmentConfigurations = 'establishment_configurations',
  MasterFranchiseConfigurations = 'master_franchise_configurations',
  Unit = 'units',
}

export default EPage;
