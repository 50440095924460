import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import {
  Space,
  Button,
  Drawer,
  Cascader,
  Typography,
  Row,
  Col,
  Divider,
} from 'antd';
import useAuth, { groupWarehouses } from '@/hooks/useAuth';
import useModal from '@/hooks/useModal';
import isScreenMobile from '@/shared/helpers/isScreenMobile';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import useBreakpoints from '@/hooks/useBreakpoint';
import TopbarProfile from './TopbarProfile';
import MyPersonalRackPicking from './MyPersonalRackPicking';

interface ITopbar {
  collapsed: boolean;
  onClickCollapsed: () => void;
}

interface IContextOption {
  key: string;
  label: string;
  options?: {
    label: string;
    value: string;
  }[];
  value?: string;
}

const Topbar: FC<ITopbar> = ({
  collapsed,
  onClickCollapsed,
}) => {
  const { t } = useCustomTranslation();
  const auth = useAuth();
  const myPersonalRackModal = useModal();

  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const [warehouseContexText, setWarehouseContexText] = useState(t('g.loading'));

  // calulate warehouses context text
  useEffect(() => {
    if (!auth) return;

    const selectedWhs = auth.selectedWarehouses.length;
    const totalWhs = auth.relatedWarehouses.length;
    // get warehouse text
    let contextWarehouseText = '';
    if (selectedWhs === totalWhs) {
      contextWarehouseText = t('g.all');
    } else {
      contextWarehouseText = `${selectedWhs}/${totalWhs} ${t('g.selected_warehouses').toLocaleLowerCase()}`;
    }
    setWarehouseContexText(contextWarehouseText);
  }, [auth?.relatedWarehouses, auth?.selectedWarehouses]);

  const warehouseContextOptions = Object.values(groupWarehouses(auth?.relatedWarehouses || [])).map(operative => ({
    label: operative.alias,
    value: operative.id.toString(),
    children: operative.warehouses.map(warehouse => ({
      label: warehouse.alias,
      value: warehouse.id.toString(),
    })),
  }));

  const handleWarehouseContextChange = (value: any, selectedOptions: any) => {
    const warehouseIds: string[] = [];
    value.forEach((selectedCouple: any) => {
      const [operativeId, warehouseId] = selectedCouple;
      warehouseIds.push(warehouseId);
    });
    auth?.setSelectedWarehouses(warehouseIds);
  };

  const warehouseContextValues: any[] = [];
  auth?.selectedWarehouses.forEach((selectedWhId) => {
    const wh = auth.relatedWarehouses.find(relatedWh => relatedWh.id.toString() === selectedWhId.toString());
    if (wh) {
      warehouseContextValues.push([wh.operativeId.toString(), wh.id.toString()]);
    }
  });

  const selectAll = () => {
    auth?.setSelectedWarehouses(auth.relatedWarehouses.map(wh => wh.id.toString()));
  };
  const deselectAll = () => auth?.setSelectedWarehouses([]);

  return (
    <Row
      align="middle"
      justify="space-between"
      className="topbar__content"
    >
      <Drawer
        open={myPersonalRackModal.visible}
        width="70%"
        title={t('g.move_products_in_inventory')}
        placement={isScreenMobile() ? 'bottom' : 'right'}
        height="90%"
        onClose={() => { myPersonalRackModal.hide(); }}
        bodyStyle={{ padding: isScreenMobile() ? 5 : undefined }}
        destroyOnClose
      >
        <MyPersonalRackPicking />
      </Drawer>
      <Col span={isBreakpointEqualsAndBelow('sm') ? '24' : undefined}>
        <Space>
          <Button
            type="text"
            size="large"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={onClickCollapsed}
          />
          <Link className="topbar__logo" to="/" />
        </Space>
      </Col>
      <Col span={isBreakpointEqualsAndBelow('sm') ? '24' : undefined}>
        <Space
          size="large"
          direction={isBreakpointEqualsAndBelow('sm') ? 'vertical' : 'horizontal'}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<SwapOutlined className="align-icon" />}
            onClick={() => (myPersonalRackModal.show())}
          />
          <Cascader
            value={warehouseContextValues}
            options={warehouseContextOptions}
            onChange={handleWarehouseContextChange}
            showCheckedStrategy={Cascader.SHOW_CHILD}
            notFoundContent={t('g.results_not_found')}
            showSearch
            multiple
            dropdownRender={(menus: React.ReactNode) => (
              <Space direction="vertical">
                {menus}
                <Divider style={{ margin: 0 }} />
                <Button type="link" onClick={selectAll}>{t('g.select_all')}</Button>
                <Button type="link" onClick={deselectAll}>{t('g.deselect_all')}</Button>
              </Space>
            )}
          >
            <Typography.Text>
              {warehouseContexText} <Typography.Link>{t('g.change')}</Typography.Link>
            </Typography.Text>
          </Cascader>
          <TopbarProfile />
        </Space>
      </Col>
    </Row>
  );
};

export default Topbar;
